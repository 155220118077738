import Vue from "vue";
import Router from "vue-router";
import routerOptions from "./routes";

import store from "@/store";
import wsconnect from "@/server";
import { isCloud } from "@/environment";

// Replicates behaviour of vue-router-next to silence navigation failure errors
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(
      this,
      location,
      onResolve,
      checkNavigationFailure(onResolve, onReject)
    );
  }
  return new Promise((resolve, reject) => {
    originalPush
      .call(this, location)
      .then(resolve)
      .catch(checkNavigationFailure(resolve, reject));
  });
};

Vue.use(Router);

export function createRouter() {
  const router = new Router(routerOptions);

  router.beforeEach(async (to, from, next) => {
    if (isAuthRoute(to)) {
      // Open websocket connection to authenticate user
      try {
        await openConnection(store);
      } catch (err) {
        console.log("Error while opening websocket connection in route guard");
        return next({ name: "login" });
      }

      if (!isAuthRouteAllowed(to)) {
        return next(defaultRoute());
      }
    }

    if (!isGeneralRouteAllowed(to)) {
      return next(defaultRoute());
    }

    // Passed all checks - navigate to target route
    return next();
  });

  // Preserve feature flag in query with each redirect
  router.beforeEach(preserveQueryFeature);

  // Add query parameter to first navigation from external source
  router.beforeEach(markExternalFrom);

  // Change document.title based on the current page
  router.afterEach((to, from) => {
    if (to.name !== from.name) {
      if (to.meta?.title) {
        document.title = "RTE Cockpit - " + to.meta.title;
      } else {
        document.title = "RTE Cockpit - " + to.name;
      }
    }
  });

  return router;

  function defaultRoute() {
    const defaults = router.options.routes.filter(
      (route) => route.meta?.default
    );
    defaults.sort((a, b) => a.meta.default - b.meta.default);
    return {
      name: defaults.find(isRouteAllowed)?.name || "403",
    };
  }
}

export function isRouteAllowed(route) {
  return (
    isGeneralRouteAllowed(route) &&
    (!isAuthRoute(route) || isAuthRouteAllowed(route))
  );
}

function isGeneralRouteAllowed(route) {
  return isRouteAllowedForCloud(route) && isRouteAllowedForSso(route);
}

function isAuthRouteAllowed(route) {
  return (
    isRouteAllowedForTraining(route) &&
    isRouteAllowedForOnPrem(route) &&
    isRouteAllowedForRole(route, store.state.user.role)
  );
}

function isAuthRoute(route) {
  return !!route.meta.roles;
}

function isRouteAllowedForSso(route) {
  return !route.meta.isSSO || store.state.isSSOEnabled;
}

function isRouteAllowedForCloud(route) {
  return route.meta.isCloud === undefined || route.meta.isCloud === isCloud;
}

function isRouteAllowedForTraining(route) {
  return route.meta.training !== false || !store.getters.isTraining;
}

function isRouteAllowedForOnPrem(route) {
  return route.meta.onPrem || !store.state.onPrem;
}

function isRouteAllowedForRole(route, role) {
  return route.meta.roles.includes(role);
}

function preserveQueryFeature(to, from, next) {
  const fromFeature = from.query.feature;
  const toFeature = to.query.feature;
  if (fromFeature && !toFeature) {
    next({ ...to, query: { ...to.query, feature: fromFeature } });
  } else {
    next();
  }
}

/**
 * Add query parameter to first navigation from external source (external="true")
 * and remove it from all subsequent navigation events.
 * NOTE: This will also add 'external' to a page when refreshed
 *
 * This is used to eg. only autofocus when coming from an internal source
 */
function markExternalFrom(to, from, next) {
  const isExternal = !from.name;
  const isMarkedExternal = to.query.external;

  if (isExternal && !isMarkedExternal) {
    next({ ...to, query: { ...to.query, external: "true" } });
  } else if (!isExternal && isMarkedExternal) {
    const query = { ...to.query };
    delete query.external;
    next({ ...to, query });
  } else {
    next();
  }
}

async function openConnection(store) {
  if (!store.state.wsconnection.isOpen) {
    await wsconnect({ store });
  }
}

function checkNavigationFailure(resolve, reject) {
  return (err) => {
    if (Router.isNavigationFailure(err)) {
      resolve(err);
    } else {
      reject(err);
    }
  };
}

export default createRouter();
