import { createApiClient } from "@/services/apiClient";
import { downloadFile } from "@/utils";

const baseURL = "v1/users";

export class UserService {
  constructor(authAPIUrl) {
    this.apiClient = createApiClient(authAPIUrl);
  }

  async getUser(id) {
    const rsp = await this.apiClient.get(`${baseURL}/${id}`);
    return {
      success: rsp.data.status === "success",
      data: mapUser(rsp.data.data),
    };
  }

  async getTeams(id) {
    const rsp = await this.apiClient.get(`${baseURL}/${id}/teams`);
    return {
      success: rsp.data.status === "success",
      data: rsp.data.data,
    };
  }

  async getOrgEntities(id) {
    const rsp = await this.apiClient.get(`${baseURL}/${id}/org_entities`);
    return {
      success: rsp.data.status === "success",
      data: rsp.data.data,
    };
  }

  async getUsers() {
    const rsp = await this.apiClient.get(`${baseURL}`);
    return {
      success: rsp.data.status === "success",
      data: rsp.data.data.results?.map(mapUser),
    };
  }

  async createUser(name, email, role, unitId) {
    const rsp = await this.apiClient.post(`${baseURL}`, {
      name: name,
      email: email,
      role: role,
      unit_id: unitId,
    });
    return {
      success: rsp.data.status === "success",
      data: rsp.data.data,
    };
  }

  async updateUser(id, email, name, unitId, role, preferredLanguage) {
    const rsp = await this.apiClient.patch(`${baseURL}/${id}`, {
      name: name,
      email: email,
      role: role,
      unit_id: unitId,
      preferred_language: preferredLanguage,
    });
    return {
      success: rsp.data.status === "success",
      data: rsp.data.data,
    };
  }

  async renameUser(id, name) {
    const rsp = await this.apiClient.patch(`${baseURL}/${id}/name`, {
      name: name,
    });
    return {
      success: rsp.data.status === "success",
    };
  }

  async deleteUser(id) {
    const rsp = await this.apiClient.delete(`${baseURL}/${id}`);
    return {
      success: rsp.data.status === "success",
    };
  }

  async search(query, role) {
    const rsp = await this.apiClient.get(`${baseURL}/search`, {
      params: { query, role },
    });
    return {
      success: rsp.data.status === "success",
      data: rsp.data.data,
    };
  }

  async changePassword(id, password) {
    const rsp = await this.apiClient.patch(
      `${baseURL}/${id}/password`,
      { password },
      { timeout: 20000 }
    );

    return {
      success: rsp.data.status === "success",
    };
  }

  async resetPassword(userId) {
    await this.apiClient.get(`${baseURL}/${userId}/forgot-password`, {
      timeout: 20000,
    });

    return {
      success: true,
    };
  }

  async exportUsers() {
    const path = `/${baseURL}/export`;
    const res = await this.apiClient.get(path, {
      responseType: "arraybuffer",
    });

    downloadFile("users_export.xlsx", res.data);
  }
}

export function mapUser(serverUser) {
  if (serverUser) {
    return {
      id: serverUser.id,
      company: serverUser.company,
      role: serverUser.role,
      name: serverUser.name,
      email: serverUser.email,
      units: serverUser.units,
      imageUrl: serverUser.image_url,
      color: serverUser.color,
      hash: serverUser.hash,
      preferredLanguage: serverUser.preferred_language,
      permissions: serverUser.permissions,
    };
  }
}
