import { RemoteCaller } from "./remoteCaller";
import { TeamService } from "@/services/team.service";
import { authAPIUrl } from "@/environment";

export class TeamHandler {
  constructor(company, getWsSession, apiVersion = 3, isUsingRest = false) {
    this.remote = new RemoteCaller(
      "auth",
      apiVersion,
      company,
      getWsSession,
      "teams"
    );
    this.isUsingRest = isUsingRest;
    this.teamService = new TeamService(authAPIUrl);
  }

  createTeam(name, unitId, members, artId) {
    if (this.isUsingRest) {
      return this.teamService.createTeam(name, unitId, members, artId);
    }
    return this.remote.call("create_team", [name], {
      unit_id: unitId,
      members,
      art_id: artId,
    });
  }

  deleteTeam(teamId) {
    if (this.isUsingRest) {
      return this.teamService.deleteTeam(teamId);
    }
    return this.remote.call("delete_team", [teamId]);
  }

  archiveTeam(teamId) {
    if (this.isUsingRest) {
      return this.teamService.archiveTeam(teamId);
    }
    return this.remote.call("archive_team", [teamId]);
  }

  async getTeams({ unitId, order } = {}) {
    if (this.isUsingRest) {
      return this.teamService.getTeams(unitId, order);
    }
    return this.remote.call("get_teams", [], {
      unit_id: unitId,
      order: order,
    });
  }

  updateTeam(teamId, name, unitId, members, artId) {
    if (this.isUsingRest) {
      return this.teamService.updateTeam(teamId, name, unitId, members, artId);
    }
    return this.remote.call("update_team", [
      teamId,
      name,
      unitId,
      members,
      artId,
    ]);
  }

  updateOidcMapping(teamId, group, role) {
    if (this.isUsingRest) {
      return this.teamService.updateOIDCMapping(teamId, group, role);
    }
    return this.remote.call("update_oidc_mapping", [teamId, group, role]);
  }

  search(name, unitId) {
    if (this.isUsingRest) {
      return this.teamService.search(name, unitId);
    }
    return this.remote.call("search", [name], {
      unit_id: unitId,
    });
  }

  getTeamMembers(teamId) {
    if (this.isUsingRest) {
      return this.teamService.getTeamMembers(teamId);
    }
    return this.remote.call("get_team_members", [teamId]);
  }

  getTeam(teamId) {
    if (this.isUsingRest) {
      return this.teamService.getTeamById(teamId);
    }
    return this.remote.call("get_team", [teamId]);
  }
}
