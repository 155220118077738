import { Replay } from "@sentry/vue";
import { environment, isCloud, sentryDsn } from "./environment";
import { ManagedError } from "@/errors";

const mockSentry = {
  init: () => {},
  setUser: () => {},
  captureException: () => {},
  captureMessage: () => {},
  configureScope: () => {},
  withScope: (callback) => {
    callback({
      setContext: () => {},
    });
  },
  addBreadcrumb: () => {},
  Replay: () => {},
};

/**
 * This functions returns true when error reporting to Sentry is enabled.
 *
 * @return {boolean}
 */
function isSentryEnabled() {
  return isCloud && sentryDsn;
}

async function loadSentry() {
  if (!loadSentry.Sentry) {
    if (isSentryEnabled()) {
      loadSentry.Sentry = await import("@sentry/vue");
    } else {
      loadSentry.Sentry = mockSentry;
    }
  }
  return loadSentry.Sentry;
}

export async function initSentry(Vue) {
  const Sentry = await loadSentry();
  Sentry.init({
    Vue,
    dsn: sentryDsn,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Replay()],
    environment,
    beforeSend(event, hint) {
      const error = hint && hint.originalException;
      if (error && error instanceof ManagedError) {
        // Don't send managed errors to Sentry
        return null;
      }
      return event;
    },
  });
}

export async function setUser(identifier) {
  const Sentry = await loadSentry();
  Sentry.setUser({ id: identifier });
}

export async function addBreadcrumb(category, additionalProperties = {}) {
  const Sentry = await loadSentry();
  Sentry.addBreadcrumb({ ...additionalProperties, category });
}

export async function resetUser() {
  const Sentry = await loadSentry();
  Sentry.configureScope((scope) => scope.setUser(null));
}

export async function captureExceptionWithContext(exception, context = {}) {
  const Sentry = await loadSentry();
  Sentry.withScope((scope) => {
    Object.entries(context).forEach(([key, value]) => {
      scope.setContext(key, value);
    });
    Sentry.captureException(exception);
  });
}

export async function captureMessageWithContext(message, context = {}) {
  const Sentry = await loadSentry();
  Sentry.withScope((scope) => {
    Object.entries(context).forEach(([key, value]) => {
      scope.setContext(key, value);
    });
    Sentry.captureMessage(message);
  });
}
