<template>
  <div class="user-mapping">
    <div style="height: 100px"></div>
    <h3>ALM user mapping</h3>
    <message-box>
      Please select your ALM user account so that user related properties of a
      sticky note (e.g. the assignee) can correctly be synchronized between
      piplanning.io and the ALM tool.
    </message-box>
    <page-loading v-if="loading"> Loading...</page-loading>
    <table v-else class="table-wrap">
      <tr class="row">
        <th class="col col-header" scope="col">Connection</th>
        <th class="col col-header" scope="col">User</th>
      </tr>
      <tr v-for="mapping in mappings" :key="mapping.sessionId" class="row">
        <td class="col">{{ mapping.type }} - {{ mapping.name }}</td>
        <td v-if="mapping.error" class="error">
          Error loading user from {{ mapping.type }}. Please check the
          configuration.
        </td>
        <td
          v-else-if="editedMapping !== mapping"
          class="col"
          :class="{ select: mapping.almId === null }"
          style="cursor: pointer"
          @click="openSearch(mapping)"
        >
          <button class="button-in-table">{{ almUserName(mapping) }} ▼</button>
          <br />
          <span v-if="isUsed(mapping)" style="font-size: 60%">
            Already used by {{ mapping.pipName }}
          </span>
        </td>
        <search-select-box
          v-else
          id="search"
          v-slot="{ item }"
          class="col"
          role="cell"
          placeholder="Type to search"
          :values="matchingUsers"
          no-values-message="No Users found"
          :loading="searching"
          @search="search($event)"
          @select="select(mapping, $event)"
          @blur="editedMapping = null"
        >
          {{ item.almName }}
          <br />
          <span v-if="isUsed(item)" style="font-size: 60%">
            Already used by {{ item.pipName }}
          </span>
        </search-select-box>
      </tr>

      <message-box v-if="isAnyUsed" type="warning">
        You assigned some ALM accounts to yourself that an other user already
        claimed. By clicking "save" the other user will lose their assignment.
      </message-box>
    </table>

    <div class="buttons">
      <template v-if="isSingleSelect">
        <base-button secondary @click="save">Save</base-button>
        <base-button @click="saveAndBack">Save and back to app</base-button>
      </template>
      <base-button v-else @click="save">Save</base-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { SessionsHandler } from "@/handlers/sessionsHandler";
import { AlmConfigurationHandler } from "@/handlers/almConfigurationHandler";
import PageLoading from "@/components/PageLoading.vue";
import SearchSelectBox from "@/components/SearchSelectBox.vue";
import BaseButton from "@/components/BaseButton.vue";
import MessageBox from "@/components/MessageBox.vue";
import { UserHandler } from "@/handlers/userHandler";
import { appUrl } from "@/utils/url";
import debounce from "lodash.debounce";
import { isFeatureEnabled } from "@/feature";

export default {
  name: "UserMapping",
  components: {
    BaseButton,
    PageLoading,
    SearchSelectBox,
    MessageBox,
  },
  data() {
    return {
      mappings: {},
      loading: true,
      editedMapping: null,
      searching: false,
      matchingUsers: [],
      isSingleSelect: false,
      noUser: {
        almId: "",
        almName: "-- Not mapped --",
      },
      pipUserCache: {},
      search: debounce(this.doSearch, 500, { leading: true }),
    };
  },
  computed: {
    ...mapGetters(["user", "company", "session"]),
    sessionsHandler() {
      return new SessionsHandler(this.company, this.session);
    },
    almConfigurationHandler() {
      return new AlmConfigurationHandler(this.company, this.session);
    },
    isRestApiEnabled() {
      return isFeatureEnabled(this.$route, "rest-api");
    },
    userHandler() {
      return new UserHandler(
        this.company,
        this.session,
        3,
        this.isRestApiEnabled
      );
    },
    isAnyUsed() {
      return Object.values(this.mappings).some((mapping) =>
        this.isUsed(mapping)
      );
    },
  },
  async mounted() {
    const sessionId = this.$route.query.mapping;
    this.mappings = await this.loadUserMappings(sessionId);
    this.loading = false;

    const mappings = Object.values(this.mappings);
    this.isSingleSelect = sessionId && mappings.length === 1;
    if (this.isSingleSelect) {
      this.openSearch(mappings[0]);
    }
  },
  methods: {
    ...mapActions("toast", ["showFeedback"]),
    async loadUserMappings(sessionId) {
      const mappings = {};
      if (sessionId) {
        try {
          await this.loadMappingForSession(mappings, sessionId);
        } catch (e) {
          // we could not load the given sessionId. Ignore and load all sessions.
        }
        if (Object.keys(mappings).length === 1) {
          return mappings;
        }
      }
      const sessions = await this.sessionsHandler.getSessions();
      for (const session of sessions) {
        if (!session.archived) {
          await this.loadMappingForSession(mappings, session.id);
        }
      }
      return mappings;
    },
    async loadMappingForSession(mappings, sessionId) {
      const sessionInfo =
        await this.almConfigurationHandler.getAlmConfiguration(sessionId);
      const connectionId = sessionInfo.alm_connection_id;
      if (connectionId && !mappings[connectionId]) {
        const almHandler = this.$almHandler(sessionInfo);
        if (sessionInfo.alm_connection_type !== "ado") {
          const almUser = await almHandler.getAlmUser();
          mappings[connectionId] = await this.resolvePipUser({
            almHandler,
            sessionId,
            type: almHandler.name,
            name: await almHandler.getName(),
            ...almUser,
          });
        }
      }
    },
    async resolvePipUser(mapping) {
      if (this.isUsed(mapping)) {
        const id = mapping.pipId;
        mapping.pipName = this.pipUserCache[id] || "";
        if (!mapping.pipName) {
          const res = await this.userHandler.getUser(id);
          mapping.pipName = this.pipUserCache[id] = res.data?.name || id;
        }
      }
      return mapping;
    },
    async doSearch(query) {
      this.searching = true;
      try {
        this.matchingUsers = [
          ...(await Promise.all(
            (
              await this.editedMapping.almHandler.searchAlmUsers(query)
            ).map(this.resolvePipUser)
          )),
          this.noUser,
        ];
      } finally {
        this.searching = false;
      }
    },
    select(mapping, almUser) {
      mapping.almId = almUser.almId;
      mapping.almName = almUser.almName;
      this.$set(mapping, "pipId", almUser.pipId);
      this.editedMapping = null;
    },
    isUsed(mapping) {
      return mapping.pipId && mapping.pipId !== this.user.id;
    },
    openSearch(mapping) {
      if (this.editedMapping !== mapping) {
        this.matchingUsers = [];
        this.editedMapping = mapping;
        this.$nextTick(() => {
          document.getElementById("search").firstElementChild.click();
        });
      }
    },
    almUserName(mapping) {
      if (mapping.almId === this.noUser.almId) {
        return this.noUser.almName;
      }
      if (!mapping.almId) {
        return "Please select a user";
      }
      if (!mapping.almName) {
        return `User not found in ${mapping.type}. ID: ${mapping.almId}`;
      }
      return mapping.almName;
    },
    save() {
      const saves = Promise.all(
        Object.values(this.mappings).map((mapping) => {
          if (mapping.almId !== undefined) {
            mapping.almHandler.setAlmUser(mapping.almId);
          }
        })
      );
      this.showFeedback({
        action: saves,
        successMessage: "The user mapping has been saved.",
      });
      return saves;
    },
    saveAndBack() {
      return this.save().then(() => {
        window.location.assign(appUrl(this.$route.query.path));
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/colors";

.user-mapping {
  margin-bottom: 300px;
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .table-wrap {
    width: 100%;
  }

  .disabled {
    color: $grey-light;
  }

  .select {
    font-weight: bold;
  }

  ::v-deep .select-list .item,
  .select-list ::v-deep .item,
  .select-list .item {
    line-height: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .row {
    display: flex;
    gap: 2em;
    width: 100%;
    height: 4em;
    align-items: center;
    border-bottom: 2px solid $grey-dark;

    .col {
      flex-basis: 100%;
    }

    .col-header {
      text-align: left;
    }

    .button-in-table {
      background: none;

      &:focus-visible {
        outline: revert;
      }
    }
  }

  .buttons {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    gap: 2em;
    justify-content: center;
  }
}
</style>
