import { createApiClient } from "@/services/apiClient";

const baseUrl = "v1/teams";

function isSuccess(rsp) {
  return rsp.data.status === "success";
}

export class TeamService {
  constructor(authAPIUrl) {
    this.apiClient = createApiClient(authAPIUrl);
  }

  async createTeam(name, unitId, members, artId) {
    const params = {
      name: name,
      unit_id: unitId || null,
      members: members,
      art_id: artId,
    };
    const rsp = await this.apiClient.post(`${baseUrl}`, params);
    return {
      success: isSuccess(rsp),
      data: rsp.data.data.id,
    };
  }

  async deleteTeam(teamId) {
    const rsp = await this.apiClient.delete(`${baseUrl}/${teamId}`);
    return {
      success: isSuccess(rsp),
    };
  }

  async archiveTeam(teamId) {
    const rsp = await this.apiClient.patch(`${baseUrl}/${teamId}/archive`);
    return {
      success: isSuccess(rsp),
    };
  }

  async getTeams(unitId, order) {
    const rsp = await this.apiClient.get(`${baseUrl}`, {
      unit_id: unitId,
      order: order,
    });
    return {
      success: isSuccess(rsp),
      data: rsp.data.data,
    };
  }

  async updateTeam(teamId, name, unitId, members, artId) {
    const params = {
      name,
      unit_id: unitId,
      members,
      art_id: artId,
    };
    const rsp = await this.apiClient.patch(`${baseUrl}/${teamId}`, params);
    return {
      success: isSuccess(rsp),
    };
  }

  async updateOIDCMapping(teamId, group, role) {
    const rsp = await this.apiClient.patch(
      `${baseUrl}/${teamId}/oidc-mapping`,
      {
        group,
        role,
      }
    );
    return {
      success: isSuccess(rsp),
    };
  }

  async search(name, unitId) {
    const rsp = await this.apiClient.get(`${baseUrl}/search`, {
      params: { name: name, unit_id: unitId },
    });
    return {
      success: isSuccess(rsp),
      data: rsp.data.data,
    };
  }

  async getTeamMembers(teamId) {
    const rsp = await this.apiClient.get(`${baseUrl}/${teamId}/members`);
    return {
      success: isSuccess(rsp),
      data: rsp.data.data,
    };
  }

  async getTeamById(teamId) {
    const rsp = await this.apiClient.get(`${baseUrl}/${teamId}`);
    return {
      success: isSuccess(rsp),
      data: rsp.data.data,
    };
  }
}
