<template>
  <div id="app">
    <skip-button @click="focusMainContent">Skip to Main Content</skip-button>
    <the-header v-if="showHeader" />
    <license-expired />
    <toast-notifications />
    <div
      class="main-content flex"
      :class="{ 'sidebar-padding': !$route.meta.hideMenu }"
    >
      <the-sidebar v-if="!!$route.meta.roles && !$route.meta.hideMenu" />

      <router-view ref="routerView" />
    </div>
    <the-footer v-if="showFooter" />
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import LicenseExpired from "@/pages/authRequired/sessions/licenseExpired.vue";
import ToastNotifications from "@/components/ToastNotifications.vue";
import TheSidebar from "@/components/TheSidebar.vue";
import TheFooter from "@/components/TheFooter.vue";
import SkipButton from "@/components/accessibility/SkipButton.vue";

export default {
  name: "App",
  components: {
    LicenseExpired,
    TheHeader,
    ToastNotifications,
    TheSidebar,
    TheFooter,
    SkipButton,
  },
  data() {
    return {
      stateStack: JSON.parse(localStorage.getItem("stateStack")),
    };
  },
  computed: {
    showHeader() {
      return !this.$route.meta.noHeader;
    },
    showFooter() {
      return ["procurement"].includes(this.$route.name);
    },
  },
  mounted() {
    let stateStack = JSON.parse(localStorage.getItem("stateStack"));
    if (stateStack && stateStack.length) {
      this.$store.commit("setStateManager", stateStack);
    }
  },
  methods: {
    /**
     * Focus the main content area (whatever's inside router-view)
     */
    focusMainContent() {
      const mainElement = this.$refs.routerView?.$el;
      if (mainElement) {
        mainElement.tabIndex = mainElement.tabIndex ?? -1;
        mainElement.focus();
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/colors.scss";
@import "@/_variables-legacy.scss";
@import "./styles/fonts.css";
@import "./styles/_resets.scss";

html {
  width: 100%;
  -webkit-tap-highlight-color: transparent;

  body,
  p {
    margin: 0;
    font-size: 1rem;
  }

  button {
    box-sizing: border-box;
    cursor: pointer;
  }
}

* {
  font-family: Mulish, sans-serif;
}

#app {
  color: $blackColor;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
}

a {
  text-decoration: none;
}

.auth-page {
  padding: 0 8px;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  box-sizing: border-box;

  .logo {
    width: 112px;
    margin-top: 50px;
    margin-bottom: 24px;
  }

  .title {
    margin-top: 38px;
  }

  .vue-form-generator {
    width: 450px;
    margin-top: 4px;
  }
}

.sp-cancel {
  display: none;
}

.sp-choose {
  margin-left: 17px;
  margin-top: 2px;
}

.pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

input {
  -webkit-appearance: none;
}

.fc-white {
  color: $whiteColor;
}

.fc-orange {
  color: $orangeColor;
}

.fc-red {
  color: $redColor;
}

.fc-black {
  color: $blackColor;
}

.fc-grey {
  color: $greyColor;
}

.fc-light-grey {
  color: $lightGreyColor;
}

.fc-dark-blue {
  color: $darkBlueColor;
}

.fc-green {
  color: $greenColor;
}

.fs-normal {
  font-size: $font-normal;
}

.fs-semi-medium {
  font-size: $font-semi-medium;
}

.fs-medium {
  font-size: $font-medium;
}

.fs-large {
  font-size: $font-large;
}

.fs-semi-extra-large {
  font-size: $font-semi-extra-large;
}

.fs-extra-large {
  font-size: $font-extra-large;
}

.line-height-125 {
  line-height: 1.25;
}

.f-center {
  text-align: center;
}

.f-right {
  text-align: right;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.space-around {
  justify-content: space-around;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.v-center {
  display: flex;
  align-items: center;
}

.h-center {
  display: flex;
  justify-content: center;
}

.h-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.g-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide,
.hidden {
  display: none;
}

.next-btn {
  pointer-events: none;
  position: relative;
  margin: 45px 0 12px;
  width: 100%;
  max-width: 284px;
  background-color: $whiteColor;
  color: $greyColor;
  font-size: 1.7rem;
  box-sizing: border-box;
  padding: 1rem;
  cursor: pointer;
  border: 3px solid $greyColor;
  transition: all 0.3s linear;

  &.valid {
    background-color: $darkBlueColor;
    color: $whiteColor;
    border-color: $darkBlueColor;
    pointer-events: auto;

    &:hover,
    &:focus-within {
      background-color: $whiteColor;
      color: $darkBlueColor;
    }

    &:focus-within {
      outline: 3px solid $purpleColor;
      outline-offset: 2px;
    }
  }

  &.red {
    &:hover {
      background-color: $whiteColor;
      color: $darkRedColor;
    }

    &.valid {
      background-color: $darkRedColor;
      border-color: $darkRedColor;
      color: $whiteColor;
    }
  }

  &.hold {
    background-color: $greyColor;
    border-color: $greyColor;
    pointer-events: none;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background: url(~@/assets/spinner.gif) no-repeat center;
      height: 60px;
      width: 60px;
    }
  }
}

::placeholder {
  color: colors.$grey-neutral-60;
  opacity: 1;
}

:-ms-input-placeholder {
  color: colors.$grey-neutral-60;
}

::-ms-input-placeholder {
  color: colors.$grey-neutral-60;
}

.error {
  color: $redColor;
  border-color: $redColor !important;
}

p.error {
  margin-top: 5px;
}

p.warning {
  color: $orangeColor;
  border-color: $orangeColor !important;
  margin-top: 5px;
}

.error::placeholder {
  color: $redColor;
}

.error:-ms-input-placeholder {
  color: $redColor;
}

.no-error {
  color: $darkBlueColor;
  border-color: $darkBlueColor !important;
}

.main-content.sidebar-padding {
  padding-left: $sidebar-width;
}

.main-section {
  width: 100%;
  max-width: 1124px;
  min-width: 800px;
  margin: 18px auto 64px auto;
  padding-top: $header-height;
}

.vue-form-generator fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.vue-form-generator .form-group {
  margin: 4% 0 0;
}

.vue-form-generator .field-wrap,
.vue-form-generator .form-group,
.vue-form-generator .field-wrap .wrapper {
  height: 60px;
  position: relative;
}

.vue-form-generator input {
  width: 100%;
  border: none;
  border-bottom: 3px solid $greyColor;
  box-sizing: border-box;
  height: 100%;
  text-align: center;
  font-size: 2rem;
}

.vue-form-generator input.valid {
  border-bottom: 3px solid $darkBlueColor;
  box-sizing: border-box;
  color: $darkBlueColor;
}

.vue-form-generator .field-input.error input {
  border-bottom: 3px solid $redColor;
  box-sizing: border-box;
  color: $redColor;
}

.vue-form-generator .field-input.error ::placeholder {
  color: $redColor;
}

.vue-form-generator input:focus {
  outline: none;
}

.vue-form-generator input:invalid,
.vue-form-generator input:-moz-submit-invalid,
.vue-form-generator input:-moz-ui-invalid {
  box-shadow: none;
}

.vue-form-generator ::-webkit-input-placeholder,
.vue-form-generator ::-moz-placeholder,
.vue-form-generator ::-ms-placeholder {
  color: $greyColor;
  font-size: 1.5rem;
  text-align: center;
}

.vue-form-generator ::placeholder {
  color: colors.$grey-neutral-60;
  font-size: 1.6rem;
  text-align: center;
}

.vue-form-generator textArea {
  width: 100%;
  border: none;
  border-bottom: 3px solid $greyColor;
  box-sizing: border-box;
  text-align: left;
  font-size: 1.2rem;
  resize: none;
}

.vue-form-generator .field-submit input {
  background-color: $darkBlueColor;
  color: $whiteColor;
  border: none;
  font-size: 1.7rem;
  box-sizing: border-box;
  transition: all 0.3s linear;
}

.vue-form-generator .field-submit .field-wrap {
  position: relative;
}

.vue-form-generator .field-input.error .errors.help-block {
  display: none;
}

.vue-form-generator .field-submit:not(.hold) input:hover,
.facilities .config button:hover,
.os-wrapper .finish-setup button:hover,
.alm-tool-configuration button.jira:hover {
  background-color: $whiteColor;
  color: $darkBlueColor;
  border: 3px solid $darkBlueColor;
}

@media (max-width: 1100px) {
  .vue-form-generator .field-wrap,
  .vue-form-generator .form-group,
  .vue-form-generator .field-wrap .wrapper {
    height: auto;
    min-height: 60px;
    position: relative;
  }

  .vue-form-generator input {
    height: 60px;
  }
}

@media (max-width: 650px) {
  .auth-page .title {
    margin: 18px 8px 4px;
    font-size: 1.5rem;
  }

  .auth-page {
    padding: 8px 12px;
  }

  .auth-page .vue-form-generator {
    width: auto;
  }
}

input.custom-checkbox,
.settings-input-wrap .input-block .form-group input.custom-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: $whiteColor;
  border: 2px solid colors.$grey-neutral-50;
  position: relative;
  width: 15px;
  min-width: 15px;
  height: 15px;
  box-sizing: border-box;
  top: 3px;
  margin-left: 40px;
}

.settings-input-wrap .input-block .form-group input.custom-checkbox {
  margin-left: 50px;
  padding-left: 0;
}

input.custom-checkbox:checked {
  background: linear-gradient(
      135deg,
      transparent 0px,
      transparent 8px,
      $darkBlueColor 8px,
      $darkBlueColor 9px,
      transparent 10px
    ),
    linear-gradient(
      225deg,
      transparent 0px,
      transparent 8px,
      $darkBlueColor 8px,
      $darkBlueColor 9px,
      transparent 10px
    );
  background-size: 12px;
  background-position: 0px -1px;
  background-repeat: no-repeat;
}

.whole-width-checkbox .form-group {
  width: 100%;
  height: 60px;
  border-bottom: 3px solid $greyColor;
}

.settings-item input.custom-checkbox:checked,
.valid input.custom-checkbox:checked,
.settings-input-wrap
  .input-block
  .valid
  .form-group
  input.custom-checkbox:checked,
.whole-width-checkbox .valid .form-group {
  border-color: $darkBlueColor;
}

.whole-width-checkbox label {
  width: 100%;
}

.link-button {
  box-sizing: border-box;
  padding: 20px 10px 18px 70px;
  background-color: $darkBlueColor;
  min-width: 320px;
  display: block;
  margin-bottom: 26px;
  background-image: url(~@/assets/button-links/docs.png);
  background-repeat: no-repeat;
  background-position: 23px center;
}

.link-button.demo {
  background-image: url(~@/assets/button-links/demo.png);
}

.link-button.docs {
  background-image: url(~@/assets/button-links/docs.png);
}

.link-button.join {
  background-image: url(~@/assets/button-links/join.png);
}

.link-button.notes {
  background-image: url(~@/assets/button-links/notes.png);
}

.link-button.play {
  background-image: url(~@/assets/button-links/play.png);
}

.link-button.roadmap {
  background-image: url(~@/assets/button-links/roadmap.png);
  background-position: 19px center;
}

.link-button-simple {
  text-align: center;
  background-color: $darkBlueColor;
  color: $whiteColor;
  padding: 19px 50px;
  cursor: pointer;
}

/*drop-down*/
.custom-select-wrap {
  position: relative;
  box-sizing: border-box;
  height: 60px;
  overflow: hidden;
  text-align: center;
  font-size: 27px;
  border-bottom: 3px solid $greyColor;
  transition: height 1s;
}

.expand-indicator {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid $darkBlueColor;
  transition: transform 0.5s;
}

.disabled .expand-indicator {
  border-bottom-color: $greyColor;
}

.loading .expand-indicator {
  background: url(~@/assets/spinner.gif) no-repeat;
  width: 30px;
  height: 30px;
  background-size: 100%;
  border: none;
  transform: none;
  transition: none;
  top: 15px;
  right: 15px;
}

.custom-select-wrap.expand {
  height: 300px;
}

.expand .expand-indicator,
.custom-select-wrap.expand .expand-indicator {
  transform: rotate(180deg);
}

.selected-option {
  height: 60px;
  cursor: pointer;
  color: colors.$grey-neutral-60;
}

.selected-option.selected {
  color: $darkBlueColor;
}

.choose-options {
  margin-top: 0;
}

.choose-options li {
  display: flex;
  align-items: center;
  height: 53px;
  margin-top: 4px;
  margin-bottom: 3px;
  margin-left: 0;
  margin-right: 0;
  background: $lightGreyColor;
  color: $blackColor;
  cursor: pointer;
  box-sizing: border-box;
  padding-left: 80px;
  padding-top: 5px;
}

.choose-options li:hover {
  background: $darkBlueColor;
  color: $whiteColor !important;
}

.general-board .inputs-wrap {
  margin-bottom: 12px;
}

.general-board .selected-option,
.general-board .choose-options li {
  padding-left: 16px;
  font-size: 1.25rem;
}

.general-board .choose-options li {
  height: 0;
  margin: 0;
  padding-top: 0;
  color: transparent;
  opacity: 0;
  transition: all 0.25s;
}

.general-board .custom-select-wrap {
  background: $whiteColor;
  width: 250px;
  overflow: visible;
  height: 60px;
  border-color: $darkBlueColor;
}

.general-board .custom-select-wrap.disabled {
  border-color: $greyColor;
}

.general-board .custom-select-wrap.expand {
  height: auto;
}

.general-board .choose-options {
  position: absolute;
  height: auto;
  overflow: hidden;
  width: 100%;
  background: $whiteColor;
  z-index: 1;
}

.general-board .custom-select-wrap.expand .choose-options {
  border-bottom: 3px solid $darkBlueColor;
}

.general-board .custom-select-wrap.expand .choose-options li {
  color: $blackColor;
  opacity: 1;
  height: 53px;
  margin: 4px 10px 3px;
}

.general-board .color-wrap input[disabled="disabled"] {
  background: $whiteColor;
}

.color-area {
  position: absolute;
  z-index: 1;
  left: 9px;
  top: 10px;
  right: 9px;
  bottom: 13px;
}

.general-board .select-wrap {
  border-bottom: 3px solid $greyColor;
  height: 60px;
  box-sizing: border-box;
  padding-left: 12px;
  width: 250px;
}

.general-board .select-wrap select {
  width: 208px;
  height: 30px;
  border-radius: 5px;
  border: 2px solid $greyColor;
  transition: width 0.2s;
}

.general-board .select-wrap select.loading {
  width: 178px;
}

.general-board .select-wrap .loading-indicator,
.general-board .form-group .loading-indicator,
.jira-team-mapping .loading-indicator,
.piplannings .loading-indicator,
.links .loading-indicator {
  background: url(~@/assets/spinner.gif) no-repeat;
  width: 30px;
  height: 30px;
  background-size: 30px;
}

.general-board .form-group .loading-indicator {
  position: absolute;
  right: -30px;
  top: 15px;
}

.server-error {
  margin: 12px 12px 12px 5px;
}

.server-error .with-icon {
  position: relative;
  box-sizing: border-box;
  padding-left: 51px;
  min-height: 41px;
  line-height: 1.1;
}

.server-error .with-icon:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  top: 0;
  background: url(~@/assets/error.png) center no-repeat;
  width: 39px;
  height: 39px;
}

.server-error.warning .with-icon:before {
  background: url(~@/assets/warning.png) center no-repeat;
  width: 40px;
  height: 41px;
}

.divider {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}

.divider:before {
  position: absolute;
  top: 0;
  left: -6rem;
  right: -6rem;
  content: "";
  height: 5px;
  background: $darkGreyColor;
}

.divider-light:before {
  height: 3px;
}

.back {
  position: absolute;
  top: 15px;
  left: 13px;
  color: $darkBlueColor;
  font-size: 1.3rem;
  cursor: pointer;
  z-index: 1;
}

.back img {
  width: 1.3rem;
  margin-right: 0.4rem;
  height: auto;
  position: relative;
  top: 2px;
  left: 2px;
}

.settings-input-wrap .input-block {
  width: 100%;
  margin-top: 26px;
}

.settings-input-wrap .input-block .form-group,
.general-information .input-block .form-group {
  width: 367px;
  height: 60px;
  margin-bottom: 4px;
}

.settings-input-wrap .input-block .form-group.full-width {
  width: 100%;
}

.settings-input-wrap .input-block .form-group input,
.general-information .input-block .form-group input,
.billing-page .input-block .form-group input,
.payment-page input {
  border: none;
  border-bottom: 3px solid $greyColor;
  box-sizing: border-box;
  font-size: 1.5rem;
  padding-left: 50px;
  height: 100%;
  width: 100%;
  outline: 0;
}

.settings-input-wrap .no-name-error .input-block .form-group .name-input,
.settings-input-wrap
  .no-username-error
  .input-block
  .form-group
  .username-input,
.settings-input-wrap .no-url-error .input-block .form-group .url-input,
.settings-input-wrap
  .no-password-error
  .input-block
  .form-group
  .password-input {
  color: $darkBlueColor;
  border-color: $darkBlueColor;
}

.settings-input-wrap .name-error .name-error-message,
.settings-input-wrap .password-error .password-error-message {
  display: block;
}

.settings-input-wrap .name-error .input-block .form-group .name-input,
.settings-input-wrap .username-error .input-block .form-group .username-input,
.settings-input-wrap .url-error .input-block .form-group .url-input,
.settings-input-wrap .password-error .input-block .form-group .password-input {
  color: $redColor;
  border-color: $redColor;
}

.settings-input-wrap
  .name-error
  .input-block
  .form-group
  .name-input::placeholder,
.settings-input-wrap
  .username-error
  .input-block
  .form-group
  .username-input::placeholder,
.settings-input-wrap
  .url-error
  .input-block
  .form-group
  .url-input::placeholder,
.settings-input-wrap
  .password-error
  .input-block
  .form-group
  .password-input::placeholder {
  color: $redColor;
}

.settings-input-wrap
  .name-error
  .input-block
  .form-group
  .name-input::-ms-input-placeholder,
.settings-input-wrap
  .username-error
  .input-block
  .form-group
  .username-input::-ms-input-placeholder,
.settings-input-wrap
  .url-error
  .input-block
  .form-group
  .url-input::-ms-input-placeholder,
.settings-input-wrap
  .password-error
  .input-block
  .form-group
  .password-input::-ms-input-placeholder {
  color: $redColor;
}

.settings-input-wrap .team-button,
.settings-input-wrap .basic-button {
  width: 40px;
  height: 40px;
}

.settings-input-wrap .add-team-button,
.settings-input-wrap .add-basic-button {
  background: url(~@/assets/buttons/plus.png) no-repeat;
  background-size: 100%;
  margin-left: 5px;
}

.settings-input-wrap .add-description {
  margin-left: 12px;
}

.settings-input-wrap .remove-team-button,
.settings-input-wrap .remove-basic-button,
.remove-field-button {
  position: absolute;
  left: 5px;
  top: 10px;
  z-index: 1;
  background: url(~@/assets/buttons/minus.png) no-repeat;
  background-size: 100%;
  opacity: 0;
  transition: opacity 0.3s linear;
}

.progressbar-wrap {
  z-index: 1;
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto 40px;
}

.progressbar {
  margin: 10px 0;

  button {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    outline: revert;
  }

  li,
  button {
    padding: 78px 1px 10px;
    box-sizing: border-box;
    width: 97px;
    list-style: none;
    position: relative;
    margin: 0;
    background: none;

    &:before {
      content: "";
      position: absolute;
      top: 10px;
      width: 55px;
      height: 55px;
      border-radius: 50%;
      background: $darkBlueColor;
      margin-bottom: 8px;
    }

    &:after {
      content: "";
      position: absolute;
      width: 57px;
      height: 3px;
      background-color: $greyColor;
      top: 36px;
      left: -26px;
      z-index: -1;
    }

    &:first-child:after {
      content: none;
    }

    &.active {
      font-weight: 700;

      &:before {
        background-color: $orangeColor;
        transform: scale(1.2);
      }
    }
  }
}

.inputs-wrap {
  margin-bottom: 12px;
}

.disable-delete .remove-basic-button {
  display: none;
}

.edit,
.actions {
  width: 35px;
  height: 30px;
  background: url(~@/assets/pen.png) no-repeat center;
  background-color: $darkBlueColor;
  margin: auto;
  cursor: pointer;
  box-sizing: border-box;
}

.actions {
  position: relative;
  background-image: url(~@/assets/dots.png);
  border: 3px solid $darkBlueColor;

  &:hover {
    background-color: $whiteColor;
    background-image: url(~@/assets/dots-blue.png);
    background-repeat: no-repeat;
    background-position: center;

    .dropdown-menu {
      display: block;
    }
  }

  .dropdown-menu {
    display: none;
    position: absolute;
    margin-top: 0;
    z-index: 1;
    right: 32px;
    border-bottom: 3px solid $greyColor;

    li {
      display: flex;
      align-items: center;
      height: 45px;
      border: 3px solid $whiteColor;
      border-top: none;
      background: $lightGreyColor;
      color: $blackColor;
      cursor: pointer;
      box-sizing: border-box;
      margin: 0;
      padding: 4px 0 0 50px;
      width: 350px;
      font-size: 1.25rem;

      &:first-of-type {
        border-top: 3px solid $whiteColor;
      }

      &:hover {
        background: $darkBlueColor;
        color: $whiteColor;
      }
    }
  }
}

.icon-box {
  position: relative;
  width: 600px;
  padding: 30px 30px 30px 60px;
  margin: auto;

  .icon {
    position: absolute;
    top: 50%;
    left: 20px;
    margin-top: -15px;
    width: 30px;
    height: 30px;
    background: center/contain no-repeat;
  }
}

.warn-box {
  border: 2px solid $darkRedColor;

  .icon {
    background-image: url(~@/assets/error.png);
  }
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.money {
  font-size: 2rem;
  color: $darkBlueColor;
}
</style>
