import { createApiClient } from "@/services/apiClient";
import { mapUser } from "@/services/user.service";

const baseProfileEndpoint = "v1/profile";

export class ProfileService {
  constructor(authAPIUrl) {
    this.apiClient = createApiClient(authAPIUrl);
  }

  async getProfile() {
    const rsp = await this.apiClient.get(baseProfileEndpoint);
    return {
      success: rsp.data.status === "success",
      data: mapUser(rsp.data.data),
    };
  }

  async requestPasswordReset() {
    const requestPasswordResetURL = `${baseProfileEndpoint}/request_password_reset`;
    const rsp = await this.apiClient.get(requestPasswordResetURL);
    return {
      success: rsp.data.status === "success",
    };
  }

  async changePreferredLanguage(language) {
    const rsp = await this.apiClient.patch(`${baseProfileEndpoint}/language`, {
      language,
    });
    return {
      success: rsp.data.status === "success",
    };
  }
}
